import { LanguageIcon } from 'components/Footer2/incons/LanguageIcon';
import { RightArrow } from 'components/Icons/RightArrow';
import LanguageModal from 'components/modals/LanguageModal';
import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/redux';
import useDomain from '../../hooks/useDomain';
import { setIsOpenLanguageModal } from '../../redux/modalSlice';

import './index.css';

export interface FooterProps {
  className?: string;
  showFull?: boolean;
}
const Footer: React.FC<FooterProps> = ({ showFull }) => {
  const { i18n, t } = useTranslation();
  const { email } = useDomain();
  const currentLanguage = i18n.language.split('-')[0];
  const dispatch = useAppDispatch();
  const token = Cookies.get('token');

  return (
    <footer className="w-full pt-[32px]">
      <LanguageModal />
      <div className="flex w-full flex-col items-center justify-between gap-5 mmd:flex-row ">
        <div className="flex justify-between gap-5 flex-col items-center mmd:items-start mmd:flex-row shrink-0">
          <nav className="flex max-w-full flex-col gap-5 text-xl items-center mmd:items-start shrink-0">
            <Link
              to="/privacy"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex  transition-all hover:opacity-75 link justify-start items-center items-center"
            >
              <p className="mr-[14px]">{t('privacy_policy')}</p>
              <RightArrow className="h-[12px] w-[12px]" />
            </Link>
            <Link
              to="/terms"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex  transition-all hover:opacity-75 link justify-start items-center"
            >
              <p className="mr-[14px]">{t('terms_of_service')}</p>
              <RightArrow className="h-[12px] w-[12px]" />
            </Link>
            <Link
              to="/refund"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex transition-all hover:opacity-75 link justify-start items-center"
            >
              <p className="mr-[14px]">{t('refund_policy')}</p>
              <RightArrow className="h-[12px] w-[12px] " />
            </Link>
            <Link
              to="/complaints"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex transition-all hover:opacity-75 link justify-start items-center"
            >
              <p className="mr-[14px]">{t('complaints_policy')}</p>
              <RightArrow className="h-[12px] w-[12px] " />
            </Link>
            {/*<Link*/}
            {/*  to="https://www.ccbillcomplaintform.com/ccbill/form/CCBillContentRemovalRequest1/formperma/sBK2jfIoZWAFw2hRRt5Rv2PQncscFzpvOH6bPcwopas"*/}
            {/*  target="_blank"*/}
            {/*  className="flex transition-all hover:opacity-75 link justify-start items-center"*/}
            {/*>*/}
            {/*  <p className="mr-[14px]">{t("content_removal")}</p>*/}
            {/*  <RightArrow className="h-[12px] w-[12px] " />*/}
            {/*</Link>*/}
          </nav>
          <ul className="flex max-w-full flex-col gap-5 text-xl lg:max-w-[400px] items-center mmd:items-start mb-[32px] mmd:mb-[unset]">
            <li className="flex justify-start">
              <nav>
                <Link
                  to="/contact"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  className="flex transition-all hover:opacity-75 link justify-start items-center"
                >
                  <p className="mr-[14px]">{t('contact')}</p>
                  <RightArrow className="h-[12px] w-[12px] " />
                </Link>
              </nav>
            </li>
            <li className="flex justify-start">
              <nav>
                <Link
                  to="/billing"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  className="flex transition-all hover:opacity-75 link justify-start items-center"
                >
                  <p className="mr-[14px]">{t('billing_support')}</p>
                  <RightArrow className="h-[12px] w-[12px] " />
                </Link>
              </nav>
            </li>
            <li
              onClick={() => dispatch(setIsOpenLanguageModal(true))}
              className="flex justify-start items-center link"
            >
              <p className="mr-[14px]">{t('language')}:</p>
              <div className="flex items-center">
                <LanguageIcon />
                <div className="bottom-0 left-0 text-[#EB7201] text-[18px]/[17px] mt-[1px] font-normal">
                  {currentLanguage.toLocaleUpperCase()[0] + currentLanguage[1]}
                </div>
              </div>
            </li>
            {token && (
              <li className="flex justify-start items-center link">
                <Link
                  to="/new-edit"
                  className="flex items-center gap-[14px]"
                >
                  <p className="new-design">{t('try_new_design')}</p>
                  <RightArrow className="h-[12px] w-[12px] fill-[#5C3DE7]" />
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="flex mmd:flex-col flex-row gap-3">
          <img
            className="w-[80px] max-h-[76px]"
            src="/icons/visa.svg"
            alt="visa payment system logo"
          />
          <img
            className="w-[80px] max-h-[76px]"
            src="/icons/mastercard.svg"
            alt="mastercard payment system logo"
          />
        </div>
        <div className="flex flex-col gap-2 text-base p-[32px] mmd:py-[16px] mmd:px-[32px] rounded-[16px] bg-black w-full mmd:max-w-[1020px]">
          <ol>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part1')}
            </li>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part2')}
            </li>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part3')}
            </li>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part4')}
            </li>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part6')}
            </li>
            <li className="text-[13px]/[22px] list-decimal mb-[5px] text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part7')}
            </li>
            <li className="text-[13px]/[22px] list-decimal text-[#9D9BA6] font-normal list-inside">
              {t('disclaimer.part8')}
            </li>
          </ol>
          <a
            className="text-[#9D9BA6] text-[13px] w-[120px]"
            href={showFull ? '/' : '/undress-ai'}
          >
            {showFull ? 'Nudify Online' : 'Undress AI'}
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
